<template>
    <div>
        <article class="page inquire">
            <div class="content-con">
                <h2 class="page-tit"><em>登入</em></h2>
                <section class="forms-basic inquire-form">
                    <input v-model="LoginViewModel.Mobile" type="number" id="inputPhone" class="d-inline-block" placeholder="请输入手机号" />
                    <input v-model="LoginViewModel.Password" type="password" id="inputPassword" placeholder="请输入密码" />
                    <p class="inquire-action"><a class="btn-active" @click="Login">登入</a></p>
                </section>
            </div>
        </article>
    </div>
</template>
<script>
    import {
        Login
    } from "@/service/api"

    export default {
        data() {
            return {
                LoginViewModel: {
                    "Merchant": location.hostname,
                    "Mobile": "",
                    "Password": ""
                },
            };
        },
        methods: {
            Login() {
                //console.log(this.LoginViewModel);
                if (this.LoginViewModel.Mobile === undefined || this.LoginViewModel.Mobile === '') {
                    this.$notify({ title: "警告", message: "手机号码必填", type: 'warning' });
                    return;
                }
                if (this.LoginViewModel.Password === undefined || this.LoginViewModel.Password === '') {
                    this.$notify({ title: "警告", message: "密码必填", type: 'warning' });
                    return;
                }

                Login(this.LoginViewModel)
                    .then((response) => {
                        //console.log(response);
                        this.$notify({ title: "成功", message: "您已登入成功", type: 'success' });
                        let username = response.data.Name;
                        localStorage.setItem("username", username);
                        localStorage.setItem("isLogin", true);
                        location.href = location.origin;
                    })
                    .catch(error => {
                        //console.log(error);
                        //console.log(error.data);
                        let errorMessage = '';
                        if (error?.data?.ErrorMessage?.length > 0) {
                            errorMessage = error?.data?.ErrorMessage;
                        }
                        this.$notify({ title: "错误", message: errorMessage, type: 'error' });
                    });
            },
        }
    };
</script>